import { PaginatedEntity } from '@shared/types/paginated.entity';
import { NotificationResponse, UnreadNotifications } from '@domain/notifications/notifications';
import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { NotificationsService } from '@services/notifications.service';
import {
	GetUnreadNotitications,
	GetUnreadNotiticationsFailure,
	GetUnreadNotiticationsSuccess,
	LoadLatestNotifications,
	LoadLatestNotificationsFailure,
	LoadLatestNotificationsSuccess,
	MarkNotificationsAsRead,
	MarkNotificationsAsReadFailure,
	MarkNotificationsAsReadSuccess,
} from '@state/notifications/notifications.actions';
import { catchError, map } from 'rxjs';
import { Message, MessageLevel } from '@state/global/global.state';
import { ShowMessage } from '@state/global/global.actions';
import { patch, updateItem } from '@ngxs/store/operators';

export interface NotificationsStateModel {
	latestNotifications?: NotificationResponse[];
	unreadNotifications?: UnreadNotifications;
}

@State<NotificationsStateModel>({
	name: 'notifications',
})
@Injectable()
export class NotificationsState {
	private translationScope = 'notifications';

	constructor(private notificationsService: NotificationsService) {}

	@Action(LoadLatestNotifications)
	loadLatestNotifications(ctx: StateContext<NotificationsStateModel>) {
		return this.notificationsService.getLatestNotifications().pipe(
				map((notifications) => ctx.dispatch(new LoadLatestNotificationsSuccess(notifications))),
				catchError((error) => ctx.dispatch(new LoadLatestNotificationsFailure(error))),
		);
	}

	@Action(LoadLatestNotificationsSuccess)
	loadLatestNotificationsSuccess(ctx: StateContext<NotificationsStateModel>, { notifications }: LoadLatestNotificationsSuccess) {
		ctx.patchState({ latestNotifications: notifications.content });
	}

	@Action(LoadLatestNotificationsFailure)
	loadLatestNotificationsFailure(ctx: StateContext<NotificationsStateModel>, { error }: LoadLatestNotificationsFailure) {
		const message: Message = {
			content: {
				title: 'load_latest_notifications_failure',
				details: error,
			},
			level: MessageLevel.ERROR,
		};
		return ctx.dispatch(new ShowMessage(message, this.translationScope));
	}

	@Action(GetUnreadNotitications)
	getUnreadNotitications(ctx: StateContext<NotificationsStateModel>) {
		return this.notificationsService.getUnreadNotifications().pipe(
			map((unreadNotifications) => ctx.dispatch(new GetUnreadNotiticationsSuccess(unreadNotifications))),
			catchError((error) => ctx.dispatch(new GetUnreadNotiticationsFailure(error))),
		)
	}

	@Action(GetUnreadNotiticationsSuccess)
	getUnreadNotiticationsSuccess(ctx: StateContext<NotificationsStateModel>, { unreadNotifications }: GetUnreadNotiticationsSuccess) {
		ctx.patchState({ unreadNotifications })
	}

	@Action(GetUnreadNotiticationsFailure)
	getUnreadNotiticationsFailure(ctx: StateContext<NotificationsStateModel>, { error }: GetUnreadNotiticationsFailure) {
		const message: Message = {
			content: {
				title: 'get_unread_notifications_failure',
				details: error,
			},
			level: MessageLevel.ERROR,
		};
		return ctx.dispatch(new ShowMessage(message, this.translationScope));
	}

	@Action(MarkNotificationsAsRead)
	markNotificationsAsRead(ctx: StateContext<NotificationsStateModel>, { ids }: MarkNotificationsAsRead) {
		return this.notificationsService.markAsRead(ids).pipe(
				map(() => ctx.dispatch(new MarkNotificationsAsReadSuccess(ids))),
				catchError((error) => ctx.dispatch(new LoadLatestNotificationsFailure(error))),
		);
	}

	@Action(MarkNotificationsAsReadSuccess)
	markNotificationsAsReadSuccess(ctx: StateContext<NotificationsStateModel>, { ids }: MarkNotificationsAsReadSuccess) {
		ctx.setState(patch({
			latestNotifications: updateItem((n) => ids.includes(n.id), patch({
				read: true,
			})),
		}));
	}

	@Action(MarkNotificationsAsReadFailure)
	markNotificationsAsReadFailure(ctx: StateContext<NotificationsStateModel>, { error }: MarkNotificationsAsReadFailure) {
		const message: Message = {
			content: {
				title: 'mark_notifications_as_read_failure',
				details: error,
			},
			level: MessageLevel.ERROR,
		};
		return ctx.dispatch(new ShowMessage(message, this.translationScope));
	}
}
