import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { DownloadFile } from './documents.actions';
import { DocumentsService } from '@services/documents.service';
import { map } from 'rxjs';


export interface DocumentsStateModel {
}
  
export const initialState: DocumentsStateModel = {};
  
export const DOCUMENTS_STATE_TOKEN: StateToken<DocumentsStateModel> = new StateToken('documents');

@State<DocumentsStateModel>({
    name: DOCUMENTS_STATE_TOKEN,
    defaults: initialState,
})
@Injectable()
export class DocumentsState {

    constructor(private documentsService: DocumentsService) {}

    @Action(DownloadFile)
    downloadFile(ctx: StateContext<DocumentsStateModel>, {fileId, filename}: DownloadFile) {
        return this.documentsService.downloadFile(fileId, filename);
    }

}