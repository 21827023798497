import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
};

const routes: Routes = [
  {
    path: 'locations',
    loadChildren: () =>
      import('@features/locations/locations.module').then(
        (m) => m.LocationsModule
      ),
  },
  {
    path: 'users',
    loadChildren: () =>
      import('@features/users/users.module').then((m) => m.UsersModule),
  },
  {
    path: 'shipments',
    loadChildren: () =>
      import('@features/shipments/shipments.module').then(
        (m) => m.ShipmentsModule
      ),
  },
  {
    path: 'organizations',
    loadChildren: () =>
      import('@features/organizations/organizations.module').then(
        (m) => m.OrganizationsModule
      ),
  },
  {
    path: 'truck-drivers',
    loadChildren: () =>
      import('@features/truck-drivers/truck-drivers.module').then(
        (m) => m.TruckDriversModule
      ),
  },
  {
    path: 'prestation-models',
    loadChildren: () =>
      import('@features/prestation-model/prestation-model.module').then(
        (m) => m.PrestationModelModule
      ),
  },
  {
    path: 'sea-routes',
    loadChildren: () =>
      import('@features/sea-routes/sea-routes.module').then(
        (m) => m.SeaRoutesModule
      ),
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
