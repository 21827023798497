import { PaginatedEntity } from '@shared/types/paginated.entity';
import { NotificationResponse, UnreadNotifications } from '@domain/notifications/notifications';

const PREFIX = '[Notifications] ';

export class LoadLatestNotifications {
	static readonly type = PREFIX + 'Load Latest Notifications';
}

export class LoadLatestNotificationsSuccess {
	static readonly type = PREFIX + 'Load Latest Notifications Success';

	constructor(public notifications: PaginatedEntity<NotificationResponse>) {}
}

export class LoadLatestNotificationsFailure {
	static readonly type = PREFIX + 'Load Latest Notifications Failure';

	constructor(public error: any) {}
}

export class GetUnreadNotitications {
	static readonly type = PREFIX + 'Get Unread Notifications';
}

export class GetUnreadNotiticationsSuccess {
	static readonly type = PREFIX + 'Get Unread Notifications Success';
	constructor(public unreadNotifications: UnreadNotifications) {}
}

export class GetUnreadNotiticationsFailure {
	static readonly type = PREFIX + 'Get Unread Notifications Failure';
	constructor(public error: any) {}
}

export class MarkNotificationsAsRead {
	static readonly type = PREFIX + 'Mark Notifications As Read';

	constructor(public ids: string[]) {}
}

export class MarkNotificationsAsReadSuccess {
	static readonly type = PREFIX + 'Mark Notifications As Read Success';

	constructor(public ids: string[]) {}
}

export class MarkNotificationsAsReadFailure {
	static readonly type = PREFIX + 'Mark Notifications As Read Failure';

	constructor(public error: any) {}
}
