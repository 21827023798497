import { USER_STATE_TOKEN, UserStateModel } from "@state/user/user.state";
import { Selector } from "@ngxs/store";


export class UserSelectors {

  @Selector([USER_STATE_TOKEN])
  static user(state: UserStateModel){
    return state.userProfile
  }
}



