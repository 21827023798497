import { KeycloakProfile } from "keycloak-js";

const PREFIX = '[User] '

export class UserLogin {
  static readonly type = PREFIX + 'User Connection'
  constructor() {
  }
}

export class UserLoginSuccess {
  static readonly type = PREFIX + 'User Connection Success'
  constructor(public connected: boolean) {
  }
}

export class UserLoginFail {
  static readonly type = PREFIX + 'User Connection Failure'
  constructor(public error: any) {
  }
}

export class UserProfileLoadSuccess {
  static readonly type = PREFIX + 'User Profile Load Success'
  constructor(public user: KeycloakProfile) {
  }
}

export class UserLogout {
  static readonly type = PREFIX + 'User Logout'
  constructor() {
  }
}

export class UserLogoutSuccess {
  static readonly type = PREFIX + 'User Logout Success'
  constructor() {
  }
}




