import { Component, Input, OnInit } from '@angular/core';
import { NgOptimizedImage } from "@angular/common";

@Component({
	selector: 'app-svg-selector',
	templateUrl: './svg-selector.component.html',
	styleUrls: ['./svg-selector.component.scss'],
	imports: [
		NgOptimizedImage
	],
	standalone: true

})
export class SvgSelectorComponent implements OnInit {
	basePath: string = "assets/images/"
	@Input() svg: string = '';
	@Input() type: 'color' | 'white-b' | 'grey' | 'white' | 'white-b-color' = 'color';

	/**
	 * On veut une taille de 38 px, donc en rem, 2.375 car 2.375 * 16 = 38
	 */
	@Input() size: number = 2.375;

	constructor() {
	}

	get suffix(): string {
		switch (this.type) {
			case 'color':
				return '_b-c';
			case 'white-b':
				return '_b-w';
			case 'white':
				return '_w';
			case 'grey':
				return '_b-g'
      case 'white-b-color':
        return '_b-w_c'
		}
	}

	ngOnInit(): void {
	}
}
