<button class="notif" mat-button [matMenuTriggerFor]="notifMenu" #menu="matMenuTrigger" disableRipple>
  <img [src]="menu.menuOpen ? 'assets/images/notif_b-c.svg' : 'assets/images/notif_b-g_c.svg'"
       [alt]="'app.notifications' | transloco"
        class="notif__icon">
  <div *ngIf="unreadNotifications$ | async | notificationShouldDisplayChip" class="notif-indicator"></div>
</button>
<mat-menu #notifMenu=matMenu xPosition="before" class="mat-elevation-z1 p-0 notif-menu h-100">
  <div class="notif-menu">
    <ng-container *ngIf="notifications$ | async as notifications">
      <div *ngFor="let notif of notifications" class="notif-menu__notif" clickStopPropagation>
        <span *ngIf="notif | notificationTitle as dayTime" class="notif-menu__notif__title">
          {{ dayTime.day }} {{ 'header.notifications.at' | transloco }} {{ dayTime.time }} {{ 'header.notifications.by' | transloco }}
          <span class="notif-menu__notif__title__sender">{{ notif.data?.triggeredByFirstname }} {{ notif.data?.triggeredByLastname }}</span>
        </span>
        <div class="notif-menu__notif__content">
          <app-notification-icon [notification]="notif"></app-notification-icon>
          <span [title]="notif.title">{{ notif.title }}</span>
        </div>
      </div>
    </ng-container>
    <app-button type="line" class="text-center" clickStopPropagation>
      {{ 'header.notifications.seeAll' | transloco }}
    </app-button>
  </div>
</mat-menu>