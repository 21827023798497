<ng-template transloco let-t>
  <app-header></app-header>
  <mat-sidenav-container>
    <mat-sidenav [opened]="visibleSideNav$ | async" mode="side" class="sidenav">
      <mat-nav-list>
        <mat-list-item [disableRipple]="true" routerLink="/locations" routerLinkActive="active" #locActive="routerLinkActive">
          <app-svg-selector class="me-1"
                            [type]="locActive.isActive ? 'color' : 'white-b-color'"
                            svg="company"
                            [size]="1.75">
          </app-svg-selector>
          <span class="align-middle text-truncate">{{ t("location.list") }}</span>
        </mat-list-item>
        <mat-list-item [disableRipple]="true" routerLink="/sea-routes" routerLinkActive="active" #seaRoutesActive="routerLinkActive">
          <app-svg-selector class="me-1"
                            [type]="seaRoutesActive.isActive ? 'color' : 'white-b-color'"
                            svg="vessel"
                            [size]="1.75">
          </app-svg-selector>
          <span class="align-middle text-truncate">{{ t("sea-routes.list") }}</span>
        </mat-list-item>
      </mat-nav-list>
      <div class="divider"></div>
      <mat-nav-list>
        <mat-list-item [disableRipple]="true" routerLink="/organizations/list/" routerLinkActive="active" #orgActive="routerLinkActive">
          <app-svg-selector class="me-1"
                            [type]="orgActive.isActive ? 'color' : 'white-b-color'"
                            svg="customer"
                            [size]="1.75">
          </app-svg-selector>
          <span class="align-middle text-truncate">{{ t("organization.list") }}</span>
        </mat-list-item>
        <mat-list-item [disableRipple]="true" routerLink="/users" routerLinkActive="active" #usrActive="routerLinkActive">
          <app-svg-selector class="me-1"
                            [type]="usrActive.isActive ? 'color' : 'white-b-color'"
                            svg="profile"
                            [size]="1.75">
          </app-svg-selector>
          <span class="align-middle text-truncate">{{ t("users.list") }}</span>
        </mat-list-item>
      </mat-nav-list>
      <div class="divider"></div>
      <mat-nav-list>
        <mat-list-item [disableRipple]="true" routerLink="/shipments" routerLinkActive="active" #shpActive="routerLinkActive">
          <app-svg-selector class="me-1"
                            [type]="shpActive.isActive ? 'color' : 'white-b-color'"
                            svg="container"
                            [size]="1.75">
          </app-svg-selector>
          <span class="align-middle text-truncate">{{ t("shipments.list") }}</span>
        </mat-list-item>
        <mat-list-item [disableRipple]="true" routerLink="/prestation-models" routerLinkActive="active" #jrnActive="routerLinkActive">
          <app-svg-selector class="me-1"
                            [type]="jrnActive.isActive ? 'color' : 'white-b-color'"
                            svg="truck"
                            [size]="1.75">
          </app-svg-selector>
          <span class="align-middle text-truncate">{{ t("prestationModels.list") }}</span>
        </mat-list-item>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-template>
