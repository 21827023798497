import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { TranslocoService } from '@ngneat/transloco';
import { NotificationResponse } from '@domain/notifications/notifications';

@Pipe({
	name: 'notificationTitle',
	standalone: true,
})
export class NotificationTitlePipe implements PipeTransform {
	constructor(private transloco: TranslocoService) {}

	transform(value: NotificationResponse) {
		const sentAt = value.sentAt || '';
		const day = DateTime.fromISO(sentAt, {
			locale: this.transloco.getActiveLang(),
		}).toRelativeCalendar();
		const time = DateTime.fromISO(sentAt, {
			locale: this.transloco.getActiveLang(),
		}).toLocaleString(DateTime.TIME_24_SIMPLE);
		return {
			day,
			time,
		}
	}
}
