import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment/environment';
import { PaginatedEntity } from '@shared/types/paginated.entity';
import { NotificationResponse, UnreadNotifications } from '@domain/notifications/notifications';

@Injectable({
	providedIn: 'root',
})
export class NotificationsService {
	constructor(private http: HttpClient) {}

	getUnreadNotifications() {
		return this.http.get<UnreadNotifications>(environment.api.bff + '/notifications/unread')
	}

	getLatestNotifications() {
		return this.http.get<PaginatedEntity<NotificationResponse>>(environment.api.bff + '/notifications', {
			params: {
				page: 0,
				size: 20,
			},
		});
	}

	markAsRead(ids: string[]) {
		return this.http.patch<void>(environment.api.bff + '/notifications/read', {}, {
			params: {
				ids,
			}
		});
	}
}
