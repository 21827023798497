import { Component, OnInit } from '@angular/core';
import { ChangeNavbar, LoadAvailablesLanguages, UpdateCurrentLang } from '@state/global/global.actions';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { GlobalStateSelector } from '@state/global/global.selectors';
import { UserSelectors } from '@state/user/user.selectors';
import { KeycloakProfile } from 'keycloak-js';
import { UserLogout } from '@state/user/user.actions';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { map } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FormatDatePipe } from '@shared/pipe/format-date.pipe';
import { NotificationsMenuComponent } from './notifications-menu/notifications-menu.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, MatToolbarModule, MatSelectModule, TranslocoModule, MatInputModule, FormsModule, MatButtonModule, MatMenuModule, FormatDatePipe, NotificationsMenuComponent],
})
export class HeaderComponent implements OnInit {
  user$: Observable<KeycloakProfile | null> = this.store.select(UserSelectors.user);
  availableLanguages$: Observable<string[]> = this.store.select(GlobalStateSelector.availableLangs);
  currentLang$: Observable<string> = this.store.select(GlobalStateSelector.currentLang);

  // TODO remove mock data
  notifications = [
    {
      sentAt: '2021-05-20T10:00:00+02:00',
      title: 'Notification 1 title is very long and should be truncated',
    },
    {
      sentAt: '2021-05-20T10:00:00+02:00',
      title: 'Notification 2',
    },
    {
      sentAt: '2021-05-20T10:00:00+02:00',
      title: 'Notification 3',
    },
  ];

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.getAvailablesLanguages();
  }

  get firstName$(): Observable<string> {
    return this.user$.pipe(
      map((user) => user?.firstName ?? ''),
    );
  }

  getAvailablesLanguages() {
    this.store.dispatch(new LoadAvailablesLanguages());
  }

  toggleVisibleSideNav() {
    this.store.dispatch(new ChangeNavbar());
  }

  changeLang(currentLang: string) {
    this.store.dispatch(new UpdateCurrentLang(currentLang));
  }

  public logout() {
    this.store.dispatch(new UserLogout());
  }
}
