import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment/environment';
import { map } from "rxjs";
import { saveAs } from 'file-saver';

@Injectable({
    providedIn: 'root',
})
export class DocumentsService {
    constructor(private http: HttpClient) {}

    downloadFile(fileId: string, filename: string) {
        return this.http.get(environment.api.bff + `/documents/${fileId}`, {responseType: 'blob'}).pipe(
            map(data => {
                saveAs(data, filename);
                // const url= window.URL.createObjectURL(data);
                // window.open(url);
                return true;
            })
        );;
    }

}
