import { KeycloakProfile } from "keycloak-js";
import {
  UserLogin,
  UserLoginFail,
  UserLoginSuccess,
  UserLogout,
  UserLogoutSuccess,
  UserProfileLoadSuccess
} from "@state/user/user.actions";
import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { KeycloakService } from "keycloak-angular";

export interface UserStateModel {
  isLoggedIn: boolean;
  userProfile: KeycloakProfile | null;
}

export const initialState: UserStateModel = {
    isLoggedIn: false,
    userProfile: null
};

export const USER_STATE_TOKEN : StateToken<UserStateModel> = new StateToken<UserStateModel>('user')

@State<UserStateModel>({
  name: USER_STATE_TOKEN,
  defaults: {
    ...initialState
  }
})
@Injectable()
export class UserState {

  constructor(private readonly keycloak: KeycloakService) {
  }

  @Action(UserLogin)
  userLogin(ctx: StateContext<UserStateModel>) {
      if(this.keycloak.isLoggedIn()) {
        return ctx.dispatch(new UserLoginSuccess(this.keycloak.isLoggedIn()))
      }
      else return ctx.dispatch(new UserLoginFail('user_not_connected'));

  }

  @Action(UserLoginSuccess)
  userLoginSuccess(ctx: StateContext<UserStateModel>, { connected }: UserLoginSuccess) {
    ctx.patchState({
      isLoggedIn: connected
    })
    return this.keycloak.loadUserProfile().then(((user: KeycloakProfile) => ctx.dispatch(new UserProfileLoadSuccess(user))));
  }

  @Action(UserProfileLoadSuccess)
  userProfileLoadSuccess(ctx: StateContext<UserStateModel>, { user }: UserProfileLoadSuccess) {
    ctx.patchState(
      {
        userProfile: user
      }
    )
  }

  @Action(UserLogout)
  userLogout(ctx: StateContext<UserStateModel>) {
    return this.keycloak.logout().then(() => {
      ctx.dispatch(new UserLogoutSuccess())
    })
  }

  @Action(UserLogoutSuccess)
  userLogoutSuccess(ctx: StateContext<UserStateModel>){
    ctx.setState({
      isLoggedIn: false,
      userProfile: null
    })
  }

}
