export const environment = {
  production: false,
  keycloak: {
    url: "https://id-dev.sirius.its-future.biz/auth",
    realm: "admcs",
    client: "admcs-public-backoffice-client"
  },
  api: {
    bff: 'https://bff-dev.sirius.its-future.biz/api'
  },
  locations: {
    list: {
      pageSize: 10
    }
  },
  notifications: {
    pollUnreadSeconds: 30
  },
  mapTiles: 'google'
};
