import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalStateSelector } from '@state/global/global.selectors';
import { Store } from '@ngxs/store';
import { HeaderComponent } from '../header/header.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { SvgSelectorComponent } from '@shared/components/svg-selector/svg-selector.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  standalone: true,
  imports: [HeaderComponent, MatSidenavModule, MatListModule, RouterModule, AsyncPipe, TranslocoModule, SvgSelectorComponent],
})
export class NavbarComponent {
  visibleSideNav$: Observable<boolean> = this.store.select(GlobalStateSelector.navbarVisible);

  constructor(private store: Store) {}
}
