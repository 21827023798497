import { Message } from '@state/global/global.state';
import { HashMap } from "@ngneat/transloco";

const PREFIX = '[Global] '


export class ShowMessage {
  static readonly type = PREFIX + 'Show message';
  constructor(public message: Message, public scope: string, public data?:HashMap) {}
}

export class ChangeNavbar {
  static readonly type = PREFIX + 'Change Navbar visibility';
}

export class CancelAction {
  static readonly type = PREFIX + 'Cancel Action';
}

export class LoadAvailablesLanguages {
  static readonly type = PREFIX + 'Load Availables Languages';
}

export class LoadAvailablesLanguagesSuccess {
  static readonly type = PREFIX + 'Load Availables Languages Success';
  constructor(public languages: string[]) {}
}

export class LoadAvailablesLanguagesFailure {
  static readonly type = PREFIX + 'Load Availables Languages Failure';
  constructor(public error: any) {}
}

export class UpdateCurrentLang {
  static readonly type = PREFIX + 'Update Current Lang';
  constructor(public currentLang: string) {}
}

export class UpdateCurrentLangSuccess {
  static readonly type = PREFIX + 'Update Current Lang Success';
}

export class UpdateCurrentLangFailure {
  static readonly type = PREFIX + 'Update Current Lang Failure';
  constructor(public error: any) {}
}

// export const showMessage = createAction(PREFIX + GlobalActions.SHOW_MESSAGE, (message: Message, scope: string) => ({
//   message,
//   scope
// }));
// export const changeNavbar = createAction(PREFIX + GlobalActions.CHANGE_NAVBAR);
//  export const cancelAction = createAction(PREFIX + GlobalActions.CANCEL_ACTIONS);

// export const loadAvailablesLanguages = createAction(PREFIX + GlobalActions.LOAD_AVAILABLE_LANGUAGES);
// export const loadAvailablesLanguagesSuccess = createAction(PREFIX + GlobalActions.LOAD_AVAILABLE_LANGUAGES_SUCCESS, props<{ languages: string[] }>());
// export const loadAvailablesLanguagesFailure = createAction(PREFIX + GlobalActions.LOAD_AVAILABLE_LANGUAGES_FAILURE, props<{ error: any }>());
//
// export const updateCurrentLang = createAction(PREFIX + GlobalActions.UPDATE_CURRENT_LANG, props<{ currentLang: string }>())
// export const updateCurrentLangSuccess = createAction(PREFIX + GlobalActions.UPDATE_CURRENT_LANG_SUCCESS)
// export const updateCurrentLangFailure = createAction(PREFIX + GlobalActions.UPDATE_CURRENT_LANG_FAILURE, props<{ error: any }>())
