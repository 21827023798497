import { Selector } from "@ngxs/store";
import { GLOBAL_STATE_TOKEN, GlobalStateModel } from "@state/global/global.state";


export class GlobalStateSelector {

  @Selector([GLOBAL_STATE_TOKEN])
  static navbarVisible(state: GlobalStateModel) {
    return state.navbarVisible;
  }

  @Selector([GLOBAL_STATE_TOKEN])
  static currentLang(state: GlobalStateModel) {
    return state.currentLang;
  }

  @Selector([GLOBAL_STATE_TOKEN])
  static availableLangs(state: GlobalStateModel) {
    return state.availableLangs;
  }
}
