<mat-toolbar class="header">
  <div class="header__container">
    <div class="header__left">
      <mat-icon (click)="toggleVisibleSideNav()">menu</mat-icon>
      <span class="header__left__title">{{ 'app.backoffice' | transloco }}</span>
      <img src="assets/images/logo.png" alt="Sirius" class="header__left__logo">
    </div>
    <div class="header__search">
      <mat-icon matPrefix>search</mat-icon>
      <input class="header__search__input" [placeholder]="'app.search' | transloco">
    </div>
    <div class="header__right">
      <app-notifications-menu></app-notifications-menu>
      <mat-select *transloco="let t; read 'languages'"
                  [ngModel]="currentLang$ | async"
                  class="header__right__lang"
                  hideSingleSelectionIndicator
                  (selectionChange)="changeLang($event.value)">
        <mat-select-trigger *ngIf="currentLang$ | async as lang" class="header__right__lang__active">
          {{ t(lang) }}
        </mat-select-trigger>
        <!-- TODO enable all languages when supported -->
        <mat-option *ngFor="let lang of availableLanguages$ | async" [value]="lang" [disabled]="lang !== 'fr'">
          {{ t(lang) }}
        </mat-option>
      </mat-select>
      <div class="header__right__logout">
        <span>{{ firstName$ | async }}</span>
        <button mat-icon-button (click)="logout()" class="header__right__logout__button">
          <mat-icon>login</mat-icon>
        </button>
      </div>
    </div>
  </div>
</mat-toolbar>
