import {
  ChangeNavbar,
  LoadAvailablesLanguages, LoadAvailablesLanguagesFailure, LoadAvailablesLanguagesSuccess,
  ShowMessage, UpdateCurrentLang,
  UpdateCurrentLangSuccess
} from "@state/global/global.actions";
import { getBrowserLang, TranslocoService } from "@ngneat/transloco";
import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { ErrorSnackbarComponent } from "../../shared/components/snackbar/error-snackbar/error-snackbar.component";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { HttpErrorResponse } from "@angular/common/http";

export const globalFeatureKey = 'globalState';

export interface GlobalStateModel {
  navbarVisible: boolean,
  availableLangs: string[],
  currentLang: string
}

export interface Message {
  content: {
    title: string,
    details?: any
  },
  level?: MessageLevel
}

export enum MessageLevel {
  ERROR = "error",
  INFO = "info",
  SUCCESS = "success"
}

export const GLOBAL_STATE_TOKEN: StateToken<GlobalStateModel> = new StateToken<GlobalStateModel>('global');

@State<GlobalStateModel>({
  name: GLOBAL_STATE_TOKEN,
  defaults: {
    navbarVisible: true,
    currentLang: getBrowserLang() || 'fr',
    availableLangs: []
  }
})
@Injectable()
export class GlobalState {

  constructor(private translocoService: TranslocoService, private _snackBar: MatSnackBar) {
  }

  @Action(ShowMessage)
  showMessage(ctx: StateContext<GlobalStateModel>, { message, scope, data }: ShowMessage) {
    if (!message.content || !message.content.title) {
      return
    }
    let title: string = '';
    let details: string | undefined;
    if (scope) {
      const subScop: string = message.level ? message.level : MessageLevel.ERROR
      title = 'messages.' + subScop.toLowerCase() + '.' + scope + '.' + message.content.title
      if (message.content.details) {
        if(message.content.details instanceof HttpErrorResponse) {
          details = 'messages.' + subScop.toLowerCase() + '.' + scope + '.' + message.content.details.error.errorCode
        } else {
          details = 'messages.' + subScop.toLowerCase() + '.' + scope + '.' + message.content.details
        }
      }
    }
    const config: MatSnackBarConfig = {
      duration: 3000,
      panelClass: 'snack-style-' + [message.level || MessageLevel.ERROR],
      horizontalPosition: "right",
      verticalPosition: "top",
    }
    const translatedTitle = this.translocoService.translate(title, data);
    if (message.level === MessageLevel.ERROR) {
      this._snackBar.openFromComponent(ErrorSnackbarComponent,
        {
          ...config,
          data: { title: translatedTitle, details }
        }
      )
    } else {
      this._snackBar.open(translatedTitle,
        "",
        {
          duration: 3000,
          panelClass: 'snack-style-' + [message.level || MessageLevel.ERROR],
          horizontalPosition: "right",
          verticalPosition: "top"
        }
      )
    }
  }

  @Action(LoadAvailablesLanguages)
  loadAvailablesLanguages(ctx: StateContext<GlobalStateModel>) {
    const langs: string[] = this.translocoService.getAvailableLangs().map(a => a.toString())
    if (langs) {
      return ctx.dispatch(new LoadAvailablesLanguagesSuccess(langs));
    }
    return ctx.dispatch(new LoadAvailablesLanguagesFailure('unable_to_load_languages'))
  }

  @Action(LoadAvailablesLanguagesSuccess)
  loadAvailablesLanguagesSuccess(ctx: StateContext<GlobalStateModel>, { languages }: LoadAvailablesLanguagesSuccess) {
    ctx.patchState({
      availableLangs: languages
    });
  }

  @Action(LoadAvailablesLanguagesFailure)
  loadAvailablesLanguagesFailure(ctx: StateContext<GlobalStateModel>, error: any) {
    const message: Message = {
      level: MessageLevel.ERROR,
      content: {
        title: error
      }
    }
    return ctx.dispatch(new ShowMessage(message, 'languages'))
  }

  @Action(UpdateCurrentLang)
  updateCurrentLang(ctx: StateContext<GlobalStateModel>, { currentLang }: UpdateCurrentLang) {
    this.translocoService.setActiveLang(currentLang);
    ctx.patchState({
      currentLang: currentLang
    });
    return ctx.dispatch(new UpdateCurrentLangSuccess());
  }

  @Action(ChangeNavbar)
  changeNavbar(ctx: StateContext<GlobalStateModel>) {
    const state = ctx.getState();
    ctx.patchState({
      navbarVisible: !state.navbarVisible
    });
  }
}
