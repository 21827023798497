import { Pipe, PipeTransform } from '@angular/core';
import { UnreadNotifications } from '@domain/notifications/notifications';

@Pipe({
	name: 'notificationShouldDisplayChip',
	standalone: true,
})
export class NotificationShouldDisplayChip implements PipeTransform {
	constructor() {}

	transform(unreadNotifications?: UnreadNotifications | null) {

		return (
			unreadNotifications && (
				unreadNotifications.NONE_CHIP && unreadNotifications.NONE_CHIP.length > 0 ||
				unreadNotifications.PUSH_SIMPLE && unreadNotifications.PUSH_SIMPLE.length > 0 ||
				unreadNotifications.PUSH_URGENT && unreadNotifications.PUSH_URGENT.length > 0
			)
		);
	}
}
