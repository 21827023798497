import { NotificationsState, NotificationsStateModel } from '@state/notifications/notifications.state';
import { Selector } from '@ngxs/store';

export class NotificationsSelector {
	@Selector([NotificationsState])
	static latestNotifications(state: NotificationsStateModel) {
		return state.latestNotifications;
	}

	@Selector([NotificationsState])
	static unreadNotifications(state: NotificationsStateModel) {
		return state.unreadNotifications;
	}
}
