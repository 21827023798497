import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { map } from 'rxjs';
import { GetUnreadNotitications, LoadLatestNotifications } from '@state/notifications/notifications.actions';
import { NotificationsSelector } from '@state/notifications/notifications.selector';
import { NotificationTitlePipe } from '@shared/pipe/notification-title.pipe';
import { NotificationIconComponent } from '@shared/components/notification-icon/notification-icon.component';
import { ButtonCustomComponent } from '@shared/components/button-custom/button-custom.component';
import { StopPropagationDirective } from '@shared/directives/stop-propagation.directive';
import { MatMenuModule } from '@angular/material/menu';
import { NotificationShouldDisplayChip } from '@shared/pipe/notification-shouldDisplayChip.pipe';
import { environment } from '@environment/environment';
import { UnsubscribeOnDestroyComponent } from '@shared/components/abstract/unsubscribe-on-destroy.component';

@Component({
  selector: 'app-notifications-menu',
  templateUrl: './notifications-menu.component.html',
  styleUrls: ['./notifications-menu.component.scss'],
  standalone: true,
  imports: [
    CommonModule, 
    TranslocoModule, 
    NotificationTitlePipe, 
    NotificationShouldDisplayChip,
    NotificationIconComponent, 
    ButtonCustomComponent, 
    StopPropagationDirective,
    MatMenuModule
  ],
})
export class NotificationsMenuComponent extends UnsubscribeOnDestroyComponent implements OnInit, OnDestroy {

  notifications$ = this.store.select(NotificationsSelector.latestNotifications);
  unreadNotifications$ = this.store.select(NotificationsSelector.unreadNotifications)

  interval: number;

  unreadNotifications: string[];

  constructor(private store: Store) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch([new LoadLatestNotifications(), new GetUnreadNotitications()]);

    this.interval = window.setInterval(() => {
      this.store.dispatch(new GetUnreadNotitications())
    }, environment.notifications.pollUnreadSeconds * 1000);

    this.subscribe(this.unreadNotifications$, )
    this.unreadNotifications$.pipe(
      map(unreadNotifications => {
        if (unreadNotifications) {
          let k: keyof typeof unreadNotifications;
          let newUnreadNotifications = [];
          for (k in unreadNotifications) {
            if (unreadNotifications[k].length > 0) {
              newUnreadNotifications.push(...unreadNotifications[k]);
            }
          }
          if (this.unreadNotificationsHasChanged(this.unreadNotifications, newUnreadNotifications)) {
              this.store.dispatch(new LoadLatestNotifications());
          }
          this.unreadNotifications = newUnreadNotifications;
        }
      })
    ).subscribe()
  }

  private unreadNotificationsHasChanged(previous: string[], next: string[]) {
    return previous && !(previous.length === next.length && next.every(notification => previous.includes(notification)))
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.interval) {
			window.clearInterval(this.interval);
		}
  }
}
