import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationResponse } from '@domain/notifications/notifications';

@Component({
  selector: 'app-notification-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './notification-icon.component.html',
  styleUrls: ['./notification-icon.component.scss']
})
export class NotificationIconComponent {
	@Input() set notification(value: NotificationResponse) {
		const subtype = value.data?.subtype;
		if (subtype) {
			this.subtype = subtype;
			this.icon = this.iconMap.get(subtype);
			this.chip = value.read ? null : this.chipColorMap.get(subtype);
		}
	}
	@Input() size: string = '1.5rem';

	subtype?: string;
	icon?: string;
	chip?: string | null;

	private iconMap = new Map<string, string>([
			['TASK_UPDATED', 'task_edited'],
			['TASK_ADDED', 'task_affected'],
			['TASK_REMOVED', 'task_removed'],
			['ADDRESS_UPDATED', 'task_edited'],
			['DOCUMENT_ADDED', 'document_added'],
	]);

	private chipColorMap = new Map<string, string>([
		['TASK_UPDATED', '#FFD600'],
		['TASK_ADDED', '#A2EE8B'],
		['TASK_REMOVED', '#6B778C'],
		['ADDRESS_UPDATED', '#FFD600'],
		['DOCUMENT_ADDED', '#00BBD4'],
	]);
}
