import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
import { TranslocoModule } from '@ngneat/transloco';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-error-snackbar',
  templateUrl: './error-snackbar.component.html',
  styleUrls: ['./error-snackbar.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslocoModule]
})
export class ErrorSnackbarComponent implements OnInit {

  title: string;
  details: string | undefined

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { title: string, details: string | undefined }) {
    console.log(data)
    this.title = data.title;
    this.details = data.details
  }

  ngOnInit(): void {
  }

}
