import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { BooleanInput } from '@angular/cdk/coercion';
import { MatInputModule } from '@angular/material/input';

@Component({
    selector: 'app-button',
    templateUrl: './button-custom.component.html',
    styleUrls: ['./button-custom.component.scss'],
    standalone: true,

  imports: [CommonModule, MatMenuModule, MatButtonModule, MatIconModule, MatInputModule],
})
export class ButtonCustomComponent {
    @Input() matMenuTriggerFor?: MatMenu;

    @Input() type: 'default' | 'line' | 'square' | 'icon' | 'white' = 'default';
    @Input() size: 'normal' | 'small' = 'normal';
    @Input() icon: string | undefined;
    @Input() subtype: 'default' | 'alt' | 'gradient' = 'default';
    @Input() disabled: BooleanInput = false;
    @Input() iconSuffix: BooleanInput = false;

    @Output() clicked: EventEmitter<void> = new EventEmitter<void>();
}
