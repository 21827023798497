import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { environment } from '@environment/environment';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatTableModule } from "@angular/material/table";
import { TranslocoRootModule } from './transloco-root.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core";
import { getBrowserLang } from "@ngneat/transloco";
import { GlobalState } from "@state/global/global.state";
import { NgxsModule } from "@ngxs/store";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsRouterPluginModule } from "@ngxs/router-plugin";
import { UserState } from "@state/user/user.state";
import { lastValueFrom } from 'rxjs';
import { merge } from 'lodash';
import { NavbarComponent } from './shared/components/layout/navbar/navbar.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DocumentsState } from '@state/documents/documents.state';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NotificationsState } from '@state/notifications/notifications.state';

function initializeEnvAndKeycloak(keycloak: KeycloakService, httpClient: HttpClient) {
  return async () => {

    // Get environment configuration from server and override local properties.
    // Keep bootstraping the app if the configuration cannot be fetched from server and
    // use bundled environment file.
    try {
      const config = await lastValueFrom(httpClient.get('/config.json'))
      merge(environment, config);
    } catch (e) {}

    return keycloak.init({
      config: {
        url: environment.keycloak.url,
        realm: environment.keycloak.realm,
        clientId: environment.keycloak.client,
      },
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: true
      },
    });
  }
}

const defaultLang = getBrowserLang() || 'fr';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    NgxsModule.forRoot([GlobalState, UserState, DocumentsState, NotificationsState], {
      developmentMode: !environment.production
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      maxAge: 25,
      disabled: environment.production
    }),
    NgxsRouterPluginModule.forRoot(),
    KeycloakAngularModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    NavbarComponent,
    MatDialogModule,
    MatNativeDateModule,
    MatDatepickerModule,
    TranslocoRootModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatTooltipModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeEnvAndKeycloak,
      multi: true,
      deps: [KeycloakService, HttpClient],
    },
    { provide: MAT_DATE_LOCALE, useValue: defaultLang }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
